import io from 'socket.io-client';
import { getToken } from './CodeeCredentials';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
var socket = null;

async function socketInit(token = false) {

    // if (!socket) {
    //     socket = await io(SOCKET_URL).on('connect', () => {})
    //     if (token) {
    //         await socketAutentica(token);
    //     }
    // }
}

async function socketEmmit(event) {
    if (socket) {
        socket.emit(event, getToken());
    }
}

async function socketSubscribe(event, callback) {
    if (socket) {
        await socket.off(event);
        socket.on(event, callback);
    }
}

async function socketAutentica(token) {
    if (socket) {
        socket.emit('jwt', token);
    }
}

async function socketDeAutentica(idUser) {
    if (socket) {
        socket.emit('jwt-remove', idUser);
    }
}

const socketEvents = {
    FARM_GET_ALL: 'FARM_GET_ALL'
}

export {
    socketInit,
    socketAutentica,
    socketDeAutentica,
    socketSubscribe,
    socketEvents,
    socketEmmit
 };