import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./history";

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = {
    "domain": process.env.REACT_APP_AUTHDOMAIN,
    "clientId": process.env.REACT_APP_AUTHCLIENTID,
    "audience": process.env.REACT_APP_AUTHAUDIENCE,
};


const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    onRedirectCallback,
    useCookiesForTransactions: true,
    cacheLocation: "localstorage",
    authorizationParams: {
        redirect_uri: window.location.origin,
        ...(config.audience ? { audience: config.audience } : null),
    },
};

ReactDOM.render(
    <Provider store={configureStore()}>
        <Suspense fallback={<div className="loading" />}>
            <Auth0Provider {...providerConfig}>
                <App />
            </Auth0Provider>
        </Suspense>
    </Provider>,
    document.getElementById('root')
);
